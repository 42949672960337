import { addCollection } from "@iconify/vue2";

// Load icons
addCollection({
  prefix: "mdi",
  icons: {
    timetable: {
      body: '<path d="M14 12h1.5v2.82l2.44 1.41l-.75 1.3L14 15.69V12M4 2h14a2 2 0 0 1 2 2v6.1c1.24 1.26 2 2.99 2 4.9a7 7 0 0 1-7 7c-1.91 0-3.64-.76-4.9-2H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2m0 13v3h4.67c-.43-.91-.67-1.93-.67-3H4m0-7h6V5H4v3m14 0V5h-6v3h6M4 13h4.29c.34-1.15.97-2.18 1.81-3H4v3m11-2.85A4.85 4.85 0 0 0 10.15 15c0 2.68 2.17 4.85 4.85 4.85A4.85 4.85 0 0 0 19.85 15c0-2.68-2.17-4.85-4.85-4.85z" fill="currentColor"/>',
    },
    "arrow-left-bold-box": {
      body: '<path fill="currentColor" d="M21 5v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2M7 12l5 5v-3h4v-4h-4V7l-5 5Z" />',
    },
    "arrow-right-bold-box": {
      body: '<path fill="currentColor" d="M3 19V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2m14-7l-5-5v3H8v4h4v3l5-5Z" />',
    },
    "check-circle": {
      body: '<path fill="currentColor" d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10s10-4.5 10-10S17.5 2 12 2m-2 15l-5-5l1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9Z"/>',
    },
    "phone-forward-outline": {
      body: '<path fill="currentColor" d="M20 15.5c-1.2 0-2.5-.2-3.6-.6h-.3c-.3 0-.5.1-.7.3l-2.2 2.2c-2.8-1.5-5.2-3.8-6.6-6.6l2.2-2.2c.3-.3.4-.7.2-1c-.3-1.1-.5-2.4-.5-3.6c0-.5-.5-1-1-1H4c-.5 0-1 .5-1 1c0 9.4 7.6 17 17 17c.5 0 1-.5 1-1v-3.5c0-.5-.5-1-1-1M5 5h1.5c.1.9.3 1.8.5 2.6L5.8 8.8C5.4 7.6 5.1 6.3 5 5m14 14c-1.3-.1-2.6-.4-3.8-.8l1.2-1.2c.8.2 1.7.4 2.6.4V19m-1-8l5-5l-5-5v3h-4v4h4v3Z" />',
    },
    "google-classroom": {
      body: '<path fill="currentColor" d="M23 2H1a1 1 0 0 0-1 1v18a1 1 0 0 0 1 1h22a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1m-1 18h-2v-1h-5v1H2V4h20v16M10.29 9.71A1.71 1.71 0 0 1 12 8c.95 0 1.71.77 1.71 1.71c0 .95-.76 1.72-1.71 1.72s-1.71-.77-1.71-1.72m-4.58 1.58c0-.71.58-1.29 1.29-1.29a1.29 1.29 0 0 1 1.29 1.29c0 .71-.58 1.28-1.29 1.28c-.71 0-1.29-.57-1.29-1.28m10 0A1.29 1.29 0 0 1 17 10a1.29 1.29 0 0 1 1.29 1.29c0 .71-.58 1.28-1.29 1.28c-.71 0-1.29-.57-1.29-1.28M20 15.14V16H4v-.86c0-.94 1.55-1.71 3-1.71c.55 0 1.11.11 1.6.3c.75-.69 2.1-1.16 3.4-1.16c1.3 0 2.65.47 3.4 1.16c.49-.19 1.05-.3 1.6-.3c1.45 0 3 .77 3 1.71Z"/>',
    },
    "calendar-sync-outline": {
      body: '<path fill="currentColor" d="M18 11v1.5c3.19 0 5.09 3.55 3.33 6.21l-1.09-1.09C21.06 15.96 19.85 14 18 14v1.5l-2.25-2.25L18 11m0 11v-1.5c-3.19 0-5.09-3.55-3.33-6.21l1.09 1.09C14.94 17.04 16.15 19 18 19v-1.5l2.25 2.25L18 22m1-19h-1V1h-2v2H8V1H6v2H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h9a5.919 5.919 0 0 1-1.5-2H5V9h14v1.59c.71.11 1.39.35 2 .72V5a2 2 0 0 0-2-2m0 4H5V5h14"/>',
    },
    "chevron-up": {
      body: '<path fill="currentColor" d="M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6l-6 6l1.41 1.41Z"/>'
    },
    "chevron-down": {
      body: '<path fill="currentColor" d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6l-6-6l1.41-1.41Z"/>'
    },
    "close-circle-outline": {
      body: '<path fill="currentColor" d="M12 20c-4.41 0-8-3.59-8-8s3.59-8 8-8s8 3.59 8 8s-3.59 8-8 8m0-18C6.47 2 2 6.47 2 12s4.47 10 10 10s10-4.47 10-10S17.53 2 12 2m2.59 6L12 10.59L9.41 8L8 9.41L10.59 12L8 14.59L9.41 16L12 13.41L14.59 16L16 14.59L13.41 12L16 9.41L14.59 8Z"/>',
    },
    "plus-circle-outline": {
      body: '<path fill="currentColor" d="M12 20c-4.41 0-8-3.59-8-8s3.59-8 8-8s8 3.59 8 8s-3.59 8-8 8m0-18A10 10 0 0 0 2 12a10 10 0 0 0 10 10a10 10 0 0 0 10-10A10 10 0 0 0 12 2m1 5h-2v4H7v2h4v4h2v-4h4v-2h-4V7Z"/>'
    }
    ,
    "language-go": {
      body: '<path fill="currentColor" d="m2.64 10.33l-.02-.06l.22-.27l.12-.08H6.8l.03.08l-.18.26l-.11.06zm-1.61.98L1 11.26l.22-.29l.12-.06h4.9l.05.09l-.08.24l-.1.07zm2.6.99l-.04-.06l.16-.28l.1-.06H6l.07.07l-.02.25l-.08.08zm11.15-2.16l-1.78.47c-.19.04-.2.05-.34-.11c-.16-.18-.27-.29-.5-.4c-.66-.34-1.33-.24-1.94.15c-.72.48-1.11 1.17-1.1 2.05c.01.86.6 1.57 1.45 1.7c.73.09 1.34-.17 1.83-.72l.29-.39h-2.07c-.22 0-.27-.14-.2-.32l.55-1.18c.03-.06.11-.17.27-.17h3.44c.15-.5.41-.96.75-1.41c.78-1.03 1.73-1.57 3-1.81c1.07-.18 2.13-.07 3.07.57c.84.58 1.37 1.36 1.5 2.39c.19 1.45-.24 2.63-1.24 3.65c-.71.72-1.58 1.17-2.57 1.39l-.86.08c-.98-.02-1.87-.3-2.62-.95c-.52-.45-.88-.99-1.06-1.63c-.15.24-.27.47-.44.7c-.77 1.02-1.78 1.65-3.06 1.8c-1.05.16-2.03-.05-2.89-.69c-.79-.6-1.26-1.4-1.36-2.39c-.14-1.17.2-2.22.91-3.14c.76-1 1.77-1.63 3.01-1.86c1-.18 1.97-.06 2.84.52c.57.38.97.9 1.24 1.52q.06.135-.12.18m6.11 1.6l-.03-.36c-.19-1.06-1.17-1.66-2.19-1.43c-1.01.22-1.67.85-1.88 1.86c-.19.84.21 1.69.98 2.03c.59.26 1.19.22 1.76-.06c.84-.43 1.31-1.12 1.36-2.04"/>'
    }

  },
  width: 24,
  height: 24,
});

addCollection({
  prefix: "entypo",
  icons: {
    blackboard: {
      body: '<path fill="currentColor" fill-rule="evenodd" d="M2.539 20H6l1.406-3.698l-2.966-1.004L2.539 20zm10.055-3.698L14 20h3.461l-1.901-4.702l-2.966 1.004zM18 2h-6.5L11 0H9l-.5 2H2a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1z" clip-rule="evenodd"></path>',
    },
  },
});

addCollection({
  prefix: "healthicons",
  icons: {
    ambulance: {





      body: '<?xml version="1.0" encoding="UTF-8" standalone="no"?> <svg aria-hidden="true" role="img" width="1.5em" height="1.5em" viewBox="0 0 16 16" class="iconify iconify--healthicons" version="1.1" id="svg3" sodipodi:docname="new2.svg" inkscape:version="1.3.1 (91b66b0783, 2023-11-16)" xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape" xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd" xmlns="http://www.w3.org/2000/svg" xmlns:svg="http://www.w3.org/2000/svg"> <defs id="defs3" /> <sodipodi:namedview id="namedview3" pagecolor="#ffffff" bordercolor="#000000" borderopacity="0.25" inkscape:showpageshadow="2" inkscape:pageopacity="0.0" inkscape:pagecheckerboard="0" inkscape:deskcolor="#d1d1d1" inkscape:zoom="35.620504" inkscape:cx="8.4221155" inkscape:cy="6.9201716" inkscape:window-width="1920" inkscape:window-height="1009" inkscape:window-x="-8" inkscape:window-y="-8" inkscape:window-maximized="1" inkscape:current-layer="svg3" /> <g fill="currentColor" id="g3" transform="matrix(0.37270472,0,0,0.37270472,-0.9449133,-2.9445302)"> <path d="m 32,12.5 a 1,1 0 1 0 2,0 V 11 a 1,1 0 1 0 -2,0 z" id="path1" /> <path fill-rule="evenodd" d="m 4,16 a 2,2 0 0 1 2,-2 h 22 a 2,2 0 0 1 1.732,1 H 30 v 4 h 1 v -1 h 1 v -1 a 1,1 0 1 1 2,0 v 1 h 1 v 1 h 0.718 a 3,3 0 0 1 2.035,0.796 l 5.282,4.875 A 3,3 0 0 1 44,26.876 V 35 h -5.126 a 4.002,4.002 0 0 1 -7.748,0 H 15.874 A 4.002,4.002 0 0 1 8.126,35 H 4 Z M 42,28 H 30 v 5 h 1.126 a 4.002,4.002 0 0 1 7.748,0 H 42 Z M 30,26 h 11.526 l -5.13,-4.735 A 1,1 0 0 0 35.718,21 H 30 Z M 12,36 a 2,2 0 1 0 0,-4 2,2 0 0 0 0,4 m 25,-2 a 2,2 0 1 1 -4,0 2,2 0 0 1 4,0 M 13,22 h 3 v -3 h 2 v 3 h 3 v 2 h -3 v 3 h -2 v -3 h -3 z" clip-rule="evenodd" id="path2" /> <path d="m 36.5,17 a 1,1 0 0 1 1,-1 H 39 a 1,1 0 1 1 0,2 h -1.5 a 1,1 0 0 1 -1,-1 m -0.671,-4.241 a 1,1 0 1 0 1.343,1.482 l 0.914,-0.828 a 1,1 0 0 0 -1.343,-1.482 z" id="path3" /> </g> </svg> '
      // '<g fill="currentColor" fill-rule="evenodd" clip-rule="evenodd"><path d="M28 15h2v4h5.718a3 3 0 0 1 2.035.796l5.282 4.875A3 3 0 0 1 44 26.876V35h-6v-2h4v-6.124a1 1 0 0 0-.322-.735l-5.282-4.876a1 1 0 0 0-.678-.265H30v12h2v2h-3.5v-1H28V15ZM12 36a2 2 0 1 0 0-4a2 2 0 0 0 0 4Zm0 2a4 4 0 1 0 0-8a4 4 0 0 0 0 8Z"></path><path d="M35 36a2 2 0 1 0 0-4a2 2 0 0 0 0 4Zm0 2a4 4 0 1 0 0-8a4 4 0 0 0 0 8Zm8-10H29v-2h14v2ZM33 16a1 1 0 0 1 1 1v3h-2v-3a1 1 0 0 1 1-1Z"></path><path d="M6 14h22a2 2 0 0 1 2 2v19H15.874a4 4 0 1 0-7.748 0H4V16a2 2 0 0 1 2-2Zm10 8v-3h2v3h3v2h-3v3h-2v-3h-3v-2h3Z"></path><path d="M14 17h6v3h3v6h-3v3h-4.682a6.002 6.002 0 0 1 2.599 4H28V16H6v17h.083A6.002 6.002 0 0 1 14 28.341V26h-3v-6h3v-3ZM8.126 33a4.007 4.007 0 0 0 0 2H4V16a2 2 0 0 1 2-2h22a2 2 0 0 1 2 2v19H15.874a4.01 4.01 0 0 0 0-2a4.002 4.002 0 0 0-7.748 0ZM16 22h-3v2h3v3h2v-3h3v-2h-3v-3h-2v3Zm19-2h-4v-2h4v2Zm-2-6.5a1 1 0 0 1-1-1V11a1 1 0 1 1 2 0v1.5a1 1 0 0 1-1 1Zm3.5 3.5a1 1 0 0 1 1-1H39a1 1 0 1 1 0 2h-1.5a1 1 0 0 1-1-1Zm-.741-2.829a1 1 0 0 1 .07-1.412l.914-.828a1 1 0 1 1 1.343 1.482l-.914.828a1 1 0 0 1-1.413-.07Z"></path></g>',
    },
    happy: {
      body: '<g fill="currentColor" fill-rule="evenodd" clip-rule="evenodd"><path d="M24 40c8.837 0 16-7.163 16-16S32.837 8 24 8S8 15.163 8 24s7.163 16 16 16Zm0 2c9.941 0 18-8.059 18-18S33.941 6 24 6S6 14.059 6 24s8.059 18 18 18Z"></path><path d="M21.286 21.249c.107.398.146.835.016 1.193a.5.5 0 0 1-.728.257a7.15 7.15 0 0 1-.766-.557l-.195-.16c-.18-.146-.354-.288-.534-.423c-.502-.375-.9-.573-1.245-.556c-.362.018-.8.271-1.362.715c-.159.125-.329.267-.505.415c-.388.324-.808.675-1.207.931a.5.5 0 0 1-.766-.352c-.05-.365.026-.821.169-1.267a5.56 5.56 0 0 1 .695-1.416c.62-.907 1.626-1.762 2.973-1.706c1.3.055 2.264.817 2.844 1.647c.292.416.498.863.61 1.279Zm5.344 0c-.108.398-.147.835-.017 1.193a.5.5 0 0 0 .729.257c.262-.158.521-.36.765-.557l.196-.16c.18-.146.354-.288.534-.423c.501-.375.9-.573 1.244-.556c.363.018.8.271 1.363.715c.158.125.328.267.505.415c.387.324.807.675 1.207.931a.5.5 0 0 0 .765-.352c.05-.365-.026-.821-.168-1.267a5.56 5.56 0 0 0-.695-1.416c-.62-.907-1.627-1.762-2.974-1.706c-1.3.055-2.263.817-2.844 1.647a4.172 4.172 0 0 0-.61 1.279ZM24 37c-4.038 0-7.538-3.048-9.258-7.5a17.99 17.99 0 0 1-.05-.129C14.246 28.18 15.2 27 16.473 27h15.055c1.273 0 2.228 1.18 1.78 2.371a17.99 17.99 0 0 1-.049.129C31.538 33.952 28.038 37 24 37Zm7.276-7.928c-.3.728-.649 1.396-1.035 2H17.759a12.46 12.46 0 0 1-.913-1.715v-.285h14.43Z"></path><path d="M31.272 33.072c.227-.295.444-.603.65-.923a14.6 14.6 0 0 0 1.385-2.778c.383-1.017-.258-2.027-1.247-2.3a2 2 0 0 0-.533-.071H16.473a2 2 0 0 0-.533.072a1.89 1.89 0 0 0-1.094.797a1.662 1.662 0 0 0-.153 1.502a17.99 17.99 0 0 0 .322.79c.31.705.666 1.37 1.063 1.988c.206.32.423.628.65.922C18.602 35.502 21.168 37 24 37c2.831 0 5.398-1.498 7.272-3.928Zm.004-4a12.776 12.776 0 0 1-1.035 2H17.759a12.46 12.46 0 0 1-.913-1.715v-.285h14.43Z"></path></g>',
    },
    "loudly-crying": {
      body: '<g fill="currentColor"><path d="M31.71 17.517c-1.752-.968-3.297-2.03-4.029-2.71L26.32 16.27c.905.843 2.614 1.997 4.423 2.997c1.801.995 3.851 1.921 5.45 2.216l.363-1.966c-1.269-.235-3.087-1.028-4.846-2Zm-11.018-2.711c-.731.68-2.276 1.743-4.028 2.71c-1.759.973-3.577 1.766-4.846 2l.364 1.967c1.598-.295 3.648-1.22 5.45-2.216c1.809-1 3.517-2.154 4.422-2.997l-1.362-1.464ZM20 31a4 4 0 0 1 8 0v2a4 4 0 0 1-8 0v-2Z"></path><path fill-rule="evenodd" d="M35.435 37.902A17.964 17.964 0 0 0 42 24c0-9.941-8.059-18-18-18S6 14.059 6 24c0 5.599 2.556 10.6 6.565 13.902C9.74 38.442 8 39.183 8 40c0 1.657 7.163 3 16 3s16-1.343 16-3c0-.817-1.74-1.557-4.565-2.098Zm-.037-2.673A15.948 15.948 0 0 0 40 24c0-8.837-7.163-16-16-16S8 15.163 8 24c0 4.33 1.72 8.259 4.515 11.14c-.14-3.524.695-6.817 1.963-9.65a9.458 9.458 0 0 0-.96.592a.5.5 0 0 1-.735-.19c-.38-.773-.122-1.788.55-2.575c.693-.812 1.864-1.454 3.499-1.57c1.6-.112 2.958.197 3.915.684c.476.243.87.54 1.14.868c.266.323.453.73.383 1.165a.5.5 0 0 1-.644.397a9.471 9.471 0 0 0-3.27-.44c-.032.11-.078.219-.138.323c-2.064 3.612-3.395 8.197-2.356 13.035A15.926 15.926 0 0 0 24 40c2.932 0 5.68-.789 8.042-2.165c1.061-4.858-.272-9.465-2.344-13.09a1.507 1.507 0 0 1-.11-.24a8.998 8.998 0 0 0-3.205.414a.5.5 0 0 1-.644-.397c-.068-.423.114-.819.37-1.13c.26-.316.64-.6 1.096-.833c.916-.467 2.216-.763 3.746-.655c1.564.111 2.687.725 3.353 1.505c.644.755.896 1.733.528 2.482a.5.5 0 0 1-.736.189a9.07 9.07 0 0 0-.557-.361c1.221 2.805 2.013 6.048 1.859 9.51Z" clip-rule="evenodd"></path></g>',
    },
    neutral: {
      body: '<g fill="currentColor"><path fill-rule="evenodd" d="M24 40c8.837 0 16-7.163 16-16S32.837 8 24 8S8 15.163 8 24s7.163 16 16 16Zm0 2c9.941 0 18-8.059 18-18S33.941 6 24 6S6 14.059 6 24s8.059 18 18 18Z" clip-rule="evenodd"></path><path d="M20 21c0 2.21-1.12 4-2.5 4S15 23.21 15 21s1.12-4 2.5-4s2.5 1.79 2.5 4Zm13 0c0 2.21-1.12 4-2.5 4S28 23.21 28 21s1.12-4 2.5-4s2.5 1.79 2.5 4Z"></path><path fill-rule="evenodd" d="M16 31a1 1 0 0 1 1-1h14a1 1 0 1 1 0 2H17a1 1 0 0 1-1-1Z" clip-rule="evenodd"></path></g>',
    },
  },
});

addCollection({
  prefix: "bi",
  icons: {
    ladder: {
      body: '<path fill="currentColor" d="M4.5 1a.5.5 0 0 1 .5.5V2h6v-.5a.5.5 0 0 1 1 0v14a.5.5 0 0 1-1 0V15H5v.5a.5.5 0 0 1-1 0v-14a.5.5 0 0 1 .5-.5zM5 14h6v-2H5v2zm0-3h6V9H5v2zm0-3h6V6H5v2zm0-3h6V3H5v2z"></path>',
    },
  },
});

addCollection({
  prefix: "pixelarticons",
  icons: {
    teach: {
      body: '<path fill="currentColor" d="M9 2H5v4h4V2zm7 7V7H2v9h2v6h2v-6h2v6h2V9h6zm-5-7h11v14H11v-2h9V4h-9V2z"></path>',
    },
  },
});

addCollection({
  prefix: "icon-park",
  icons: {
    report: {
      body: '<g fill="none" stroke="#000" stroke-linejoin="round" stroke-width="4"><path fill="#2F88FF" d="M36 35H12V21C12 14.3726 17.3726 9 24 9C30.6274 9 36 14.3726 36 21V35Z"/><path stroke-linecap="round" d="M8 42H40"/><path stroke-linecap="round" d="M4 13L7 14"/><path stroke-linecap="round" d="M13 3.9999L14 6.9999"/><path stroke-linecap="round" d="M10.0001 9.99989L7.00009 6.99989"/></g>',
    },
    "report-xoutline": {
      body: '<g fill="none" stroke="currentColor" stroke-linejoin="round" stroke-width="4"><path d="M36 35H12V21c0-6.627 5.373-12 12-12s12 5.373 12 12v14Z"/><path stroke-linecap="round" d="M8 42h32M4 13l3 1m6-10l1 3m-4 3L7 7"/></g>',
    },
  },
});


addCollection({
  prefix: "game-icons",
  icons: {
    "stop-sign": {
      body: '<g fill="currentColor"><path d="M3.16 10.08c-.931 0-1.447-.493-1.494-1.132h.653c.065.346.396.583.891.583c.524 0 .83-.246.83-.62c0-.303-.203-.467-.637-.572l-.656-.164c-.61-.147-.978-.51-.978-1.078c0-.706.597-1.184 1.444-1.184c.853 0 1.386.475 1.436 1.087h-.645c-.064-.32-.352-.542-.797-.542c-.472 0-.77.246-.77.6c0 .261.196.437.553.522l.654.161c.673.164 1.06.487 1.06 1.11c0 .736-.574 1.228-1.544 1.228Zm3.427-3.51V10h-.665V6.57H4.753V6h3.006v.568H6.587Z"/><path fill-rule="evenodd" d="M11.045 7.73v.544c0 1.131-.636 1.805-1.661 1.805c-1.026 0-1.664-.674-1.664-1.805V7.73c0-1.136.638-1.807 1.664-1.807s1.66.674 1.66 1.807Zm-.674.547v-.553c0-.827-.422-1.234-.987-1.234c-.572 0-.99.407-.99 1.234v.553c0 .83.418 1.237.99 1.237c.565 0 .987-.408.987-1.237m1.15-2.276h1.535c.82 0 1.316.55 1.316 1.292c0 .747-.501 1.289-1.321 1.289h-.865V10h-.665zm1.436 2.036c.463 0 .735-.272.735-.744s-.272-.741-.735-.741h-.774v1.485z"/><path fill-rule="evenodd" d="M4.893 0a.5.5 0 0 0-.353.146L.146 4.54A.5.5 0 0 0 0 4.893v6.214a.5.5 0 0 0 .146.353l4.394 4.394a.5.5 0 0 0 .353.146h6.214a.5.5 0 0 0 .353-.146l4.394-4.394a.5.5 0 0 0 .146-.353V4.893a.5.5 0 0 0-.146-.353L11.46.146A.5.5 0 0 0 11.107 0zM1 5.1L5.1 1h5.8L15 5.1v5.8L10.9 15H5.1L1 10.9z"/></g>'
    }
  }
})

addCollection({
  prefix: "gridicons",
  icons: {
    visible: {
      body: '<path fill="black" d="M12 6C5.188 6 1 12 1 12s4.188 6 11 6s11-6 11-6s-4.188-6-11-6zm0 10c-3.943 0-6.926-2.484-8.379-4c1.04-1.085 2.862-2.657 5.254-3.469A3.96 3.96 0 0 0 8 11a4 4 0 0 0 8 0a3.96 3.96 0 0 0-.875-2.469c2.393.812 4.216 2.385 5.254 3.469c-1.455 1.518-4.437 4-8.379 4z"/>',
    },
    "not-visible": {
      body: '<path fill="black" d="M1 12s4.188-6 11-6c.947 0 1.839.121 2.678.322L8.36 12.64A3.96 3.96 0 0 1 8 11c0-.937.335-1.787.875-2.469c-2.392.812-4.214 2.385-5.254 3.469a14.868 14.868 0 0 0 2.98 2.398l-1.453 1.453C2.497 14.13 1 12 1 12zm22 0s-4.188 6-11 6c-.946 0-1.836-.124-2.676-.323L5 22l-1.5-1.5l17-17L22 5l-3.147 3.147C21.501 9.869 23 12 23 12zm-2.615.006a14.83 14.83 0 0 0-2.987-2.403L16 11a4 4 0 0 1-4 4l-.947.947c.31.031.624.053.947.053c3.978 0 6.943-2.478 8.385-3.994z"/>',
    },
  },
});
