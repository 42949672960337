<template>
  <nav ref="navMain" class="navbar" role="navigation" aria-label="main navigation">
    <div class="navbar-brand">
      <router-link class="navbar-item" :to="{ path: '/home' }">
        <img :src="connected
          ? '/img/senses-connected.svg'
          : '/img/senses-connecting.svg'
          " width="112" height="28" />
      </router-link>

      <div v-if="isStaff" class="navbar-item">
        <div class="buttons">
          <router-link class="button" :class="newMessages ? 'is-dark pulsing' : 'is-outlined'"
            :to="{ path: '/school/message' }">Messages</router-link>
        </div>
      </div>
      <div v-if="isStaff && hasDataDumpRequest" class="navbar-item">
        <div class="buttons">
          <router-link class="button" :class="dataDumpNeed ? 'is-dark pulsing' : 'is-outlined'"
            :to="{ name: 'schoolDataDump' }">Data</router-link>
        </div>
      </div>

      <a id="example-burger" role="button" class="navbar-burger burger" aria-label="menu" aria-expanded="false"
        data-target="main-menu">
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>

    <div id="main-menu" class="navbar-menu">
      <div class="navbar-start">
        <div v-if="isStaff" class="navbar-item has-dropdown is-hoverable">
          <a class="navbar-link"> Students </a>
          <div class="navbar-dropdown">
            <router-link class="navbar-item" :to="{ path: '/school/timetable/class/' }">
              Register by Class
            </router-link>
            <router-link v-if="isLevel25" class="navbar-item" :to="{ path: '/school/register/missing' }">
              Missing
            </router-link>
            <router-link class="navbar-item" :to="{ path: '/school/student/search/' }">
              Search
            </router-link>
            <router-link class="navbar-item" :to="{ path: '/school/timetable/staff' }">
              All Staff Registers
            </router-link>
            <router-link v-if="$store.getters.canPutStudentOnReport" class="navbar-item" :to="{
              path: '/school/student/attendance/',
            }">
              Attendance Monitor
            </router-link>


          </div>
        </div>

        <div v-if="$testMode() && isStudent" class="navbar-item has-dropdown is-hoverable">
          <a class="navbar-link"> My Lessons </a>
          <div class="navbar-dropdown">

            <router-link v-if="hasExams" class="navbar-item" :to="{ name: 'mySchoolExams' }">
              Exam Timetable
            </router-link>
          </div>
        </div>

        <div v-if="$testMode() && isStaff" class="navbar-item has-dropdown is-hoverable">
          <a class="navbar-link"> Staff </a>
          <div class="navbar-dropdown">
            <router-link v-if="isLevel25" class="navbar-item" :to="{ path: '/school/staff/list/' }">
              Search
            </router-link>
            <router-link class="navbar-item" :to="{ path: '/school/timetable/staff' }">
              All Staff Registers
            </router-link>
          </div>
        </div>

        <div v-if="isStaff && isLevel25" class="navbar-item has-dropdown is-hoverable">
          <a class="navbar-link"> School </a>
          <div class="navbar-dropdown">
            <router-link v-if="$testMode()" class="navbar-item" :to="{ path: '/school/settings/' }">
              Settings
            </router-link>

            <router-link class="navbar-item" :to="{
              path: '/school/timetable/room/' + $store.state.currentProperDate,
            }">
              Room
            </router-link>

          </div>
        </div>


        <div v-if="isStaff" class="navbar-item has-dropdown is-hoverable">
          <a class="navbar-link"> Manage </a>
          <div class="navbar-dropdown">
            <router-link v-if="requiredDepartment(47)" class="navbar-item" :to="{ path: '/lunch/' }">
              Lunches
            </router-link>
            <router-link v-if="isATutor" class="navbar-item" :to="{ path: '/school/tutor/mine' }">
              My Tutor
            </router-link>
            <router-link v-if="$testMode()" class="navbar-item" :to="{ path: '/school/upload' }">
              External Data
            </router-link>
            <router-link v-if="isLevel25" class="navbar-item" :to="{ path: '/school/datadump/manage' }">
              Datadump
            </router-link>
            <router-link v-if="isLevel25" class="navbar-item" :to="{ name: 'formsList' }">
              Forms
            </router-link>

            <router-link v-if="isLevel25" class="navbar-item" :to="{ name: 'schoolNextSteps' }">
              Next Steps
            </router-link>

            <router-link v-if="isLevel25" class="navbar-item" :to="{ name: 'schoolConsents' }">
              Consents
            </router-link>



            <router-link v-if="isLevel25" class="navbar-item" :to="{ path: '/helpdesk/' }">
              Helpdesk
            </router-link>

            <router-link v-if="isLevel25" class="navbar-item" :to="{ path: '/school/links/manage/' }">
              Useful Links
            </router-link>



            <router-link v-if="isLevel25" class="navbar-item" :to="{ path: '/system/' }">
              System
            </router-link>

            <router-link v-if="$store.getters.canPutStudentOnReport" class="navbar-item" :to="{
              path: '/school/timetable/cover/' + $store.state.currentProperDate,
            }">
              Cover
            </router-link>

            <router-link v-if="$store.getters.canPutStudentOnReport" class="navbar-item" :to="{
              path: '/school/timetable/room/' + $store.state.currentProperDate,
            }">
              Room
            </router-link>

            <router-link v-if="$testMode()" class="navbar-item" :to="{ path: '/school/groups/manage/' }">
              Groups Manage
            </router-link>

            <router-link v-if="isLevel25" class="navbar-item" :to="{ path: '/school/staff/' }">
              Staff
            </router-link>

            <router-link v-if="isLevel25" class="navbar-item" :to="{ path: '/school/files/manage/' }">
              File Manager
            </router-link>
          </div>
        </div>

        <router-link v-if="isStaff" class="navbar-item" :to="{ path: '/school/links' }">
          Useful Links
        </router-link>
      </div>

      <div class="navbar-end">
        <div class="navbar-item" v-if="$testMode()">
          <div class="buttons">
            <button class="button is-outlined is-dark" type="button" @click="forceJoin()">
              "Force WS Join"
            </button>
          </div>
        </div>
        <div class="navbar-item" v-if="$testMode() && user?._delegate?.accessToken">
          <div class="buttons">
            <button class="button is-outlined is-dark" type="button" v-clipboard:copy="user._delegate.accessToken"
              v-clipboard:success="onCopy" v-clipboard:error="onError">
              Copy Token!
            </button>
          </div>
        </div>

        <div class="navbar-item" v-if="isStudent">
          <div class="buttons">
            <router-link class="button is-outlined is-dark" :to="{ name: 'viewsMySchoolTeachers' }">
              My Teachers
            </router-link>
          </div>
        </div>

        <div class="navbar-item" v-if="isStaff">
          <div class="buttons">
            <a class="button is-outlined is-dark" href="https://sense-services.canny.io/feature-requests"
              target="_blank">Feedback</a>
          </div>
        </div>
        <div class="navbar-item" v-if="isStaff">
          <div class="buttons">
            <a class="button is-outlined is-dark" @click="showPeopleSearchModel()">Search</a>
          </div>

        </div>

        <div class="navbar-item">
          <div class="buttons" @click="showSystemModalFlag = true">
            <a class="button is-outlined is-dark">System</a>
          </div>
        </div>
        <div v-if="loggedIn" class="navbar-item">
          <div class="buttons" @click="logout">
            <a class="button is-outlined is-dark">Logout</a>
          </div>
        </div>
      </div>
    </div>
    <PersonSearch ref="xyz" v-if="isStaff" />
    <div class="modal" :class="{ 'is-active': showSystemModalFlag }">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">System Information</p>
          <button class="delete" aria-label="close" @click="showSystemModalFlag = false">
            >
          </button>
        </header>
        <section class="modal-card-body">
          <p><strong>Version:</strong> {{ version.version.substring(version.version.length - 8) }}</p>
          <p><strong>Date:</strong> {{ version.date }}</p>
        </section>
        <footer class="modal-card-foot">
          <button class="button is-success" @click="showSystemModalFlag = false">
            Ok
          </button>
        </footer>
      </div>
    </div>
  </nav>
</template>

<script>

import firebase from "firebase/compat/app";
import PersonSearch from "Components/PersonSearch.vue";
import "firebase/compat/auth";

import { bus } from "Plugins/addressbus.js"

import GitCommit from "../_git_commit";

import { checkedRequiredDepartment } from "Helpers/functions.js";
import { forceJoin } from "Helpers/apicalls.js";

//const ls = require( 'local-storage')

export default {
  name: "componentsMyNavbar",

  components: {
    PersonSearch
  },

  computed: {
    connected: function () {
      return this.$store.get("connected");
    },
    domainsAllowed: function () {
      return this.$store.getters.domainsAllowed.length > 0;
    },


    loggedIn: function () {
      return firebase.auth().currentUser !== null;
    },

    newMessages: function () {
      if (!this.$store.getters.newMessages) {
        return false;
      }

      return this.$router.history.current.fullPath != "/school/message";
    },

    hasDataDumpRequest: function () {
      return this.$store.getters.hasDataDumpRequest;
    },

    dataDumpNeed: function () {
      console.log(this.$store.dataDumpNumber);



      if (!this.$store.getters.hasDataDumpRequest) {
        return false;
      }

      return this.$router.history.current.fullPath != "/school/datadumps";
    },

    hasExams() {
      if (this.$store.getters.currentDomainDetails.exams?.timetable?.[0]) {
        return true;
      }
      return false;
    },

    version() {
      return GitCommit;
    },

    isLevel25: function () {
      return this.$store.getters.isAccessLevel(25);
    },

    isStaff() {
      return this.$store.getters.isStaff;
    },

    isStudent() {
      return this.$store.getters.isStudent;
    },
  },

  methods: {
    forceJoin: function () {
      forceJoin();
    },
    onCopy: function (e) {
      alert("You just copied: " + e.text);
    },
    onError: function (e) {
      alert("Failed to copy text: " + e);
    },

    requiredDepartment: function (departments) {
      return checkedRequiredDepartment(this, departments);
    },

    isATutor: function () {
      return (
        Object.keys(this.$store.getters.currentUserDetails["tutorgroups"]) > 0
      );
    },

    showPeopleSearchModel() {
      this.$refs.xyz.showModal("wwww", "wwww")
    },

    logout: function () {
      firebase
        .auth()
        .signOut()
        .then(() => {
          localStorage.removeItem("ssUser");
          this.$router.replace("/login");
        });
    },

    changeDomain: function () {
      this.$router.replace("/school/chooser");
    },
  },
  data: function () {
    return {
      user: firebase.auth().currentUser,
      showSystemModalFlag: false,
    };
  },
  mounted: function () {
    const burger = document.getElementById("example-burger");
    const menu = document.getElementById("main-menu");
    burger.addEventListener("click", function () {
      burger.classList.toggle("is-active");
      menu.classList.toggle("is-active");
    });



    bus.$on('show-person-search', (options) => {
      this.$refs.xyz.showModalNew(options.message, options.title, options)
    })

    this.$refs.navMain.addEventListener(
      "click",
      function (e) {
        if (e.target.classList.contains("navbar-item")) {
          menu.classList.remove("is-active");
          burger.classList.remove("is-active");
        }
      },
      false
    );
  },
};
</script>

<style>
.pulsing {
  animation: pulser ease-in-out 1s 2;
  animation-iteration-count: infinite;
}

@keyframes pulser {
  0% {
    background-color: #00d1b2;
    color: #000;
  }

  100% {
    background-color: #fff;
    color: #000;
  }
}

.flashing {
  animation: blinking ease-in-out 1s 2;
  animation-iteration-count: infinite;
}

@keyframes blinking {
  0% {
    color: #485fc7;
  }

  100% {
    color: red;
  }
}
</style>
