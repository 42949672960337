import Vuex from "vuex";
import Vue from "vue";
import pathify from "./pathify";
import format from "date-fns/format";
import api from "Helpers/apicalls";
import { getReferenceData } from "Classes/reference";
import forms from "./stores/forms";
import menu from "./stores/menu";
import consents from "./stores/consents";
import datadump from "./stores/datadumps";
//import nextSteps from "./stores/nextsteps";
import { make } from "vuex-pathify";
import storeMutations from "./store-mutations";
//import { default as api, parseJwt } from "Helpers/apicalls";


import { memoPromise } from "Helpers/memopromise.js";
const memoizedFetch = memoPromise(api.get2, 5000)


const ls = require("local-storage");

Vue.use(Vuex);

const state = {
  isLoadingFull: false,
  loading: { full: {} },
  showNavigation: true,
  organisation: {
    name: "The King's School",
  },
  realtimeClient: null,
  channel: null,
  inPortal: false,
  lastInteraction: null,
  timeOfDay: "Day",
  currentDate: format(new Date(), "cccc, do LLL yyyy"),
  currentDateObj: new Date(),
  currentTime: format(new Date(), "pp"),
  currentHM: format(new Date(), "HH:mm"),
  timerReference: null,

  serviceWorkerBroadcast: null,
  fcmToken: null,

  currentDomain: null,
  currentTimeTable: null,
  currentProperDate: null,
  currentISODate: null,
  connected: false,

  currentGroup: null,
  currentRegGroup: null,
  currentGroupUpdate: -1,
  currentGroupStudents: {},
  currentPupil: null,
  currentStaffSelected: null,
  currentPupilData: null,

  afterInterstitial: undefined,

  newAuthDetails: {},
  newJWT: null,
  wsID: null,
  ws: null,

  newAuthRefresh: null,

  datadumpNumber: 0,
  datadumpLastRerieved: null,


  lastSeenMessages: ls("lastSeen") ?? 0,
  lastReceivedMessage: 0,
  isStaff: false,
  isStudent: false,
  isAdult: false,
  isGuest: false,
  referenceData: {},
  profile: undefined,
  messages: [],
  currentUserObj: null,
  currentUserDataNew: {
    nextCheck: "0",
    getting: false,
    domains: ls("ssUserNew"),
  },

  formsList: [],
  streams: [],

}


const mutations = {
  ...make.mutations(state),
  ...storeMutations,
}

const store = new Vuex.Store({
  plugins: [pathify.plugin],

  state,
  mutations,

  modules: {
    forms,
    menu,
    consents,
    datadump
    //nextSteps
  },

  getters: {

    menuToday: (state) => {

      return state.currentUserDataNew?.domains?.[state.currentDomain]?.menu;


    },


    getCurrentGroupStudent:
      (state) =>
        (student = null) => {
          if (student === null) {
            return state.currentGroupStudents;
          }

          return state.currentGroupStudents[student.toString()];
        },


    currentTimeTable: (state) => {
      return state.currentUserDataNew?.domains?.[state.currentDomain]?.rtt;
    },

    getInterstitial: (state) => {
      return state.afterInterstitial;
    },

    newMessages: (state) => {
      return state.lastSeenMessages < state.lastReceivedMessage;
    },

    isAuthenticatedUser: (state) => {
      return state.currentUserObj !== null;
    },

    domainsAllowed: (state) => {
      return Object.keys(state.currentUserDataNew?.domains ?? {});
    },

    hasDataDumpRequest: (state) => {
      return (state.datadumpNumber ?? 0) > 0;
    },

    isStaff: (state) => {
      return state.isStaff;
    },

    isStudent: (state) => {
      return state.isStudent;
    },

    isAdult: (state) => {
      return state.isAdult;
    },

    isGuest: (state) => {
      return state.isGuest;
    },

    isValidUserForDomain: (state) => {
      return state.isStaff || state.isStudent || state.isAdult || state.isGuest;
    },

    isAccessLevel: (state) => (level) => {

      console.log("Checking access level:", level)
      if (!state.currentDomain) {
        return false;
      }
      if (!state.isStaff) {
        return false;
      }

      if (!state.currentUserDataNew?.domains?.[state.currentDomain]) {
        return false;
      }

      return (
        (state.currentUserDataNew.domains[state.currentDomain].staff
          ?.AccessLevel ?? 0) >= level
      );
    },

    accessLevel: (state) => {


      if (!state.currentDomain) {
        return -1;
      }
      if (!state.isStaff) {
        return -1;
      }

      if (!state.currentUserDataNew?.domains?.[state.currentDomain]) {
        return -1;
      }

      return state.currentUserDataNew.domains[state.currentDomain].staff?.AccessLevel ?? 0

    },

    timetableDay: (state) => {
      return state.currentTimeTable?.data?.[0]?.timetableDayName ?? "";
    },

    isTimetabled: (state) => {
      if (!state.currentTimeTable?.data?.[0]) {
        return false;
      }

      let date = new Date(state.currentTimeTable.data[0].date)
        .toISOString()
        .slice(0, 10);
      let today = new Date().toISOString().slice(0, 10);

      return date === today;
    },

    currentDomainDetails: (state) => {
      if (!state.currentDomain) {
        return {};
      }

      if (!state.currentUserDataNew.domains[state.currentDomain]) {
        return {};
      }

      return state.currentUserDataNew.domains[state.currentDomain];
    },

    authenticatedUserDetails: (state) => {
      return state.currentUserObj;
    },

    canPutStudentOnReport: (state, getters) => {
      if (getters.isAccessLevel(25)) {
        return true;
      }

      return [
        "d.hampton@thekingsschool.org.uk",
        "b.langmead@thekingsschool.org.uk",
        "a.howard@thekingsschool.org.uk",
      ].includes(state.currentUserObj?.email ?? "_");
    },

    referenceData:
      (state) =>
        (id, key = null) => {
          if (key) {
            return state.referenceData?.[id]?.[key] ?? {};
          }
          return state.referenceData?.[id] ?? {};
        },

    currentUserDetails: (state) => {
      if (!state.currentDomain) {
        console.log("No domain");
        return {};
      }

      if (!state.currentUserDataNew?.domains?.[state.currentDomain]) {
        console.log("No domain in user data");
        return {};
      }

      return (
        state.currentUserDataNew.domains[state.currentDomain].staff ??
        state.currentUserDataNew.domains[state.currentDomain].student ??
        state.currentUserDataNew.domains[state.currentDomain].adult ??
        {}
      );
    },
  },

  actions: {
    setFCMToken({ state }, token) {
      state.fcmToken = token
    },


    updateDataDumpNumber: async ({ state }) => {

      if (!state.isStaff) {
        return
      }



      const data = await memoizedFetch("assessment/staff?count=1", true);

      state.datadumpNumber = data?.total ?? 0
      state.datadumpLastRerieved = state.currentDateObj

      console.log(data)


    },


    async setLoadingFull({ state }, key) {
      state.loading.full[key] = 1;
      state.isLoadingFull = true;
    },

    currentUser({ commit, _dispatch, _state }, user) {
      commit("setCurrentUserObj", user);

      if (!user) {
        commit("resetAuthenticatedData");
        return;
      }
    },

    setCurrentDomain: async ({ _commit, _dispatch, state }, domain) => {
      state.isStaff = false;
      state.isStudent = false;
      state.isAdult = false;
      state.isGuest = false;
      state.profile = undefined;
      state.referenceData = {};
      state.newAuthDetails = {}
      state.newJWT = null
      state.wsID = null

      if (state.ws) {
        state.ws.close()
        state.ws = null
      }









      if (!state.currentUserDataNew?.domains?.[domain]) {
        state.currentDomain = null;
        return false;
      }

      state.currentDomain = domain;

      state.currentGroup = null;
      state.currentRegGroup = null;

      if (typeof state.currentUserDataNew.domains[domain].guest === "object") {
        state.isGuest = true;
        state.profile = state.profile ?? "guest";
        api.getNewAccessToken()
        return;
      }

      if (typeof state.currentUserDataNew.domains[domain].adult === "object") {
        state.isAdult = true;
        state.profile = state.profile ?? "adult";
        api.getNewAccessToken()
        return;
      }

      if (
        typeof state.currentUserDataNew.domains[domain].student === "object"
      ) {
        state.isStudent = true;
        state.profile = state.profile ?? "student";
        api.getNewAccessToken()
        return;
      }

      if (typeof state.currentUserDataNew.domains[domain].staff === "object") {
        state.isStaff = true;
        state.profile = "staff";
        api.getNewAccessToken()
        return;
      }

      state.currentDomain = null;
    },

    async setWsID({ state }, value) {
      state.wsID = value
    },

    async clearLoadingFull({ state }, key) {
      delete state.loading.full[key];
      let x = Object.keys(state.loading?.full ?? {});
      state.isLoadingFull = x.length > 0;
    },

    async updateReferenceData() {
      console.log("Updating reference data");
      if (this.state.profile === undefined) {
        console.log("Updating reference data: No profile set");
        return;
      }

      //getReferenceData;

      const reference = await getReferenceData(this.state.profile);

      console.log(reference)

      this.commit("referenceData", {
        type: this.state.profile,
        data: reference,
      });
    },

    async updateTime({ state }) {

      if (!state.timerReference) {
        console.log("CREATING TIMER FOR UPDATE TIME")

        state.timerReference = setInterval(() => {

          this.commit("updateTimeMut");
        }, 1000);
      }
    }
    ,
  },
});

pathify.debug();

export default store;
